a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus, a:focus, a:active {
  outline: 0;
  text-decoration: none;
}

body {
  color: #777;
  background-color: #fff;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 13px;
  line-height: 22px;
}

.row {
  margin-bottom: 70px;
}

[class^="row"] [class^="row"]:last-child {
  margin-bottom: 0;
}

p, a, span {
  color: #777;
  font-family: Open Sans, Arial, sans-serif;
  line-height: 22px;
}

p {
  padding-bottom: 15px;
}

a {
  -o-transition: all .2s ease 0s;
  text-decoration: none;
  transition: all .2s;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin-top: 0;
  margin-bottom: 25px;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 30px;
  line-height: 30px;
}

h2 {
  font-size: 24px;
  line-height: 26px;
}

h3 {
  font-size: 21px;
  line-height: 24px;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
  line-height: 18px;
}

h6 {
  font-size: 15px;
  line-height: 18px;
}

strong {
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

img.float-left {
  float: left;
  margin: 12px 12px 12px 0;
}

img.float-right {
  float: right;
  margin: 12px 0 12px 12px;
}

.img-fixed-bottom {
  position: relative;
  bottom: -70px;
}

ul, ol {
  list-style-position: inside;
}

ul li, ol li {
  padding-bottom: 5px;
}

blockquote + p {
  margin-top: 15px;
}

.required {
  color: #ce292d;
}

.text-big {
  color: #333;
  font-size: 18px;
  line-height: 25px;
}

.align-right {
  text-align: right;
}

a.read-more {
  float: right;
  position: relative;
}

a.read-more span {
  text-transform: uppercase;
  -o-transition: all .2s ease 0s;
  font-weight: 700;
  transition: all .2s;
  display: block;
}

a.read-more:hover span {
  transform: translate(-15px);
}

a.read-more i {
  opacity: 0;
  -o-transition: all .2s ease 0s;
  font-size: 11px;
  line-height: 20px;
  transition: all .2s;
}

a.read-more:hover i {
  opacity: 1;
}

a.download-link {
  width: 100%;
  margin-bottom: 5px;
  display: block;
}

a.download-link span {
  -o-transition: all .2s ease 0s;
  transition: all .2s;
}

a.download-link span i {
  padding-right: 5px;
}

.mb-70 {
  margin-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.svg-white .st0 {
  fill: #fff;
}

.header-wrapper {
  width: 100%;
  z-index: 1000;
  background: #fff;
  position: fixed;
  top: 0;
  box-shadow: 0 0 5px #0003;
}

.header-wrapper.header-transparent {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background: none;
}

.header-wrapper.header-transparent02 {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: #fff;
}

.header-wrapper.header-transparent.solid-color, .header-wrapper.header-transparent02.solid-color {
  background-color: #fff;
  box-shadow: 0 0 5px #0003;
}

.header-wrapper.header-transparent.solid-color .header-style01 .main-nav {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background: #fff;
  border: none;
}

#header {
  z-index: 1000;
  position: relative;
}

#search {
  width: 40px;
  min-height: 80px;
  cursor: pointer;
  float: right;
  z-index: 1005;
  background-color: #0000;
  margin-left: 20px;
  margin-right: 30px;
  padding-top: 20px;
  position: relative;
}

.search-submit {
  width: 40px;
  min-height: 40px;
  text-indent: -9999px;
  cursor: pointer;
  -o-transition: all .2s ease 0s;
  background-image: url("search.ca34069e.png");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  transition: all .2s;
  position: absolute;
}

#search:focus, .search-submit:focus, #m_search:focus {
  outline: none;
}

#m_search {
  width: 170px;
  color: #666;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px 25px;
  font-style: italic;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #0000;
}

.main-nav .row {
  margin-bottom: 0;
}

.main-nav {
  background: -o-linear-gradient(top, #fff 0%, #fcfcfc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #fcfcfc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #fcfcfc 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fcfcfc));
  background: -webkit-linear-gradient(top, #fff 0%, #fcfcfc 100%);
  border-radius: 3px;
  margin-top: 35px;
  box-shadow: 0 -8px #fff3;
}

.navbar {
  min-height: 80px;
  margin-bottom: 0;
}

.navbar-default {
  background: none;
  border: none;
}

#dl-menu {
  display: none;
}

.navbar-nav > li {
  padding-bottom: 0;
}

.navbar-nav > li > a {
  color: #333;
  text-transform: uppercase;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 48px;
}

.navbar-default .navbar-nav > li > a {
  color: #333;
  border-bottom: 3px solid #0000;
}

.nav > li > a {
  padding-left: 25px;
  padding-right: 25px;
}

.header-style01 .navbar-default .navbar-nav > li > a:hover {
  border-bottom: 3px solid;
}

.dropdown-menu {
  min-width: 240px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 2px 2px 5px #32323233;
}

.dropdown .dropdown-menu .dropdown-submenu > a {
  position: relative;
}

.dropdown .dropdown-menu .dropdown-submenu > a:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #aaa;
  margin-top: -3px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.dropdown-menu > li {
  padding: 0 20px;
}

.dropdown-menu > li > a {
  color: #333;
  border-bottom: 1px dotted #ddd;
  padding: 15px 0;
  font-size: 13px;
}

.dropdown-menu > li:last-child a {
  border: none;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background: none;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: -3px;
  left: 100%;
  box-shadow: 0 3px #0000001a;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown.dropdown-submenu > a {
  position: relative;
}

.navbar-header {
  padding-top: 5px;
  padding-left: 5px;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border: none;
}

.navbar-collapse {
  float: right;
  padding: 0;
}

.nav > li.current-menu-item > a {
  border-bottom: 3px solid;
}

.header-style01 #top-bar-wrapper .row {
  margin-bottom: 0;
}

.header-style01 #quick-links {
  padding-top: 8px;
}

.header-style01 #quick-links li {
  float: left;
  padding-bottom: 0;
  padding-right: 30px;
  list-style: none;
}

.header-style01 #quick-links li span, .header-style01 #quick-links li a {
  color: #fff;
}

.header-style01 #top-bar-wrapper {
  width: 100%;
  padding: 20px 0 0;
}

.header-style02 #top-bar-wrapper .row {
  margin-bottom: 0;
}

.header-style02 #quick-links {
  padding-top: 8px;
}

.header-style02 #quick-links li {
  float: left;
  padding-bottom: 0;
  padding-right: 30px;
  list-style: none;
}

.header-style02 #top-bar-wrapper {
  width: 100%;
  background-color: #fcfcfc;
  padding: 10px 0;
}

.header-style02 .navbar-header {
  padding-left: 0;
}

.header-style02 #search {
  margin-right: 0;
}

.wpml-languages {
  float: right;
  display: block;
  position: relative;
}

.wpml-languages a.active {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 8px 6px 8px 10px;
  display: block;
}

.wpml-languages a.active i {
  margin-left: 5px;
  font-size: 9px;
  position: relative;
  top: -2px;
}

.wpml-languages a.active img {
  width: 22px;
  float: left;
}

.wpml-languages a img {
  width: 22px;
}

.wpml-lang-dropdown {
  width: 100%;
  z-index: 10001;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  overflow: hidden;
}

.wpml-lang-dropdown li {
  padding: 8px 15px;
  list-style: none;
}

.wpml-languages:hover ul.wpml-lang-dropdown {
  display: block;
}

.header-style02 .header-inner {
  padding: 20px 0;
}

.header-style02 .header-inner .main-nav {
  background: none;
  margin-top: 0;
}

.header-style02 .navbar-default .navbar-nav > li > a:hover, .header-style02 .nav > li.current-menu-item > a {
  border: #0000;
}

.header-style02 .navbar-default .navbar-nav > li > a {
  border-bottom: none;
}

.header-style02 #top-bar-wrapper.dark {
  background-color: #333;
}

.header-style02 #top-bar-wrapper.dark #quick-links li span, .header-style02 #top-bar-wrapper.dark #quick-links li a {
  color: #ccc;
}

#top-bar-wrapper.dark .wpml-languages a.active, #top-bar-wrapper.dark .wpml-lang-dropdown {
  background-color: #444;
  border: 1px solid #555;
}

#top-bar-wrapper.dark .wpml-languages a.active i {
  color: #ccc;
}

.header-wrapper.dark #top-bar-wrapper {
  background-color: #222;
}

.header-wrapper.dark #quick-links li span, .header-wrapper.dark #quick-links li a {
  color: #ccc;
}

.header-wrapper.dark .wpml-languages a.active, .header-wrapper.dark .wpml-lang-dropdown {
  background-color: #333;
  border: 1px solid #444;
}

.header-wrapper.dark .wpml-languages a.active i {
  color: #ccc;
}

.header-wrapper.dark {
  background-color: #333;
}

.header-wrapper.dark .main-nav {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.header-wrapper.dark .navbar-default .navbar-nav > li > a {
  color: #fff;
}

.header-wrapper.dark .dropdown-menu {
  background-color: #333;
  border: 1px solid #444;
}

.header-wrapper.dark .dropdown-menu > li > a {
  color: #888;
  border-bottom: 1px dotted #444;
}

.header-wrapper.dark .dropdown-menu > li > a:hover {
  color: #fff;
}

.header-wrapper.dark .dropdown .dropdown-menu .dropdown-submenu > a:after {
  border-left: 5px solid #888;
}

.header-wrapper.dark .dropdown .dropdown-menu .dropdown-submenu > a:hover:after {
  border-left: 5px solid #fff;
}

.header-wrapper.dark #m_search {
  color: #ccc;
  background: #333;
  border: 1px solid #444;
}

.header-style03 .info-container {
  float: right;
}

.header-style03 .info-container #quick-links, .header-style03 .info-container .wpml-languages, .header-style03 .info-container #search {
  float: left;
  padding: 0 10px;
}

.header-style03 .info-container #quick-links {
  padding-top: 10px;
}

.header-style03 #quick-links {
  padding-top: 8px;
}

.header-style03 #quick-links li {
  float: left;
  padding-bottom: 0;
  padding-right: 30px;
  list-style: none;
}

.header-style03 .wpml-lang-dropdown {
  width: auto;
  left: 10px;
}

.header-style03 #search {
  min-height: 60px;
}

.header-style03 .logo-info-container {
  padding: 20px 0;
}

.header-style03 .logo-info-container .row {
  margin-bottom: 0;
}

.header-style03 .main-nav {
  background: none;
  border-top: 1px solid #eee;
  margin-top: 0;
}

.header-style03 .navbar-default .navbar-collapse, .navbar-default .navbar-form {
  float: left;
  width: 100%;
}

.header-style03 .navbar-nav > li > a {
  line-height: 30px;
}

.header-style03 .navbar {
  min-height: 30px;
}

.header-style03 .nav > li.current-menu-item > a {
  border-bottom: none;
}

.header-style03 .navbar a.btn {
  position: relative;
  top: 10px;
}

.header-wrapper.dark .header-style03 .main-nav {
  border-top: 1px solid #444;
}

.page-title-style01 {
  margin-bottom: 70px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.page-title-negative-top {
  padding-top: 185px;
  margin-top: 0 !important;
}

.page-title-style01 .row, .page-title-style02 .row {
  margin-bottom: 0;
}

.page-title-style01 h1 {
  color: #fff;
  text-align: center;
}

.breadcrumb-container {
  width: 100%;
  float: left;
}

.page-title-style01 .breadcrumb {
  margin: 0 auto;
  display: table;
}

.breadcrumb li {
  float: left;
  color: #fff;
  padding: 0 0 0 3px;
  list-style: none;
}

.breadcrumb li a {
  color: #fff;
}

.breadcrumb li + li:before {
  content: "/";
  color: #fff;
  padding: 0 8px;
  font-size: 12px;
}

.breadcrumb li:nth-child(2):before {
  color: #0000;
}

.page-title-style02 {
  margin-bottom: 70px;
  padding: 70px 0;
}

.page-title-style02 h1 {
  color: #fff;
  margin-bottom: 0;
}

.page-title-style02 .breadcrumb-container {
  float: right;
  width: auto;
}

.pt-bkg01 {
  background-image: url("page-title01.d66ba96f.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg02 {
  background-image: url("page-title02.bae394b6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg03 {
  background-image: url("page-title03.73ba8a8f.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg04 {
  background-image: url("page-title04.28ec0612.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg05 {
  background-image: url("page-title05.8d0bebeb.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg06 {
  background-image: url("page-title06.95042d42.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg07 {
  background-image: url("page-title07.9fd9d1c2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg08 {
  background-image: url("page-title08.9f219db6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg09 {
  background-image: url("page-title09.7096a296.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg10 {
  background-image: url("page-title10.ad56ad02.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg11 {
  background-image: url("page-title11.7798b94b.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg12 {
  background-image: url("page-title12.855a0664.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg13 {
  background-image: url("page-title13.0a35ddf8.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg14 {
  background-image: url("page-title14.ae3957b2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg15 {
  background-image: url("page-title15.38854fbd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pt-bkg16 {
  background-image: url("page-title16.5e1621f1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.page-content.custom-bkg {
  padding-top: 70px;
}

.page-content.column-img-bkg {
  padding: 0;
}

.page-content.column-img-bkg .row {
  margin-bottom: 0;
}

.custom-col-padding-both {
  padding: 120px 70px;
}

.custom-col-padding-bottom {
  padding-bottom: 120px;
}

.img-bkg01.visible {
  background-image: url("img-bkg01.a50febd5.jpg");
}

@media (min-width: 991px) {
  .img-bkg01.visible {
    height: 653px !important;
  }
}

.img-bkg02 {
  background-image: url("img-bkg02.6884d749.jpg");
}

.img-bkg03 {
  background-image: url("img-bkg03.3bc63e20.jpg");
}

[class*="img-bkg"] {
  background-size: cover;
}

.custom-bkg.bkg-grey {
  background-color: #f6f6f6;
}

.custom-bkg.bkg-light-blue {
  background-color: #f4fcfc;
}

.custom-bkg.bkg-dark-blue {
  background-color: #2c3741;
}

.custom-bkg.bkg-dark-blue.transparent01 {
  background-color: #25272eb3;
}

.custom-bkg.bkg-dark-blue.transparent02 {
  background-color: #25272ecc;
}

.custom-bkg.bkg-dark-blue.transparent03 {
  background-color: #25272ee6;
}

.page-title-style01 + .page-content.custom-bkg, .page-title-style02 + .page-content.custom-bkg {
  margin-top: -70px;
}

.page-content.parallax {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 100px;
  padding-bottom: 30px;
}

.parallax01 {
  background-image: url("parallax01.4940cf2c.jpg");
}

.parallax02 {
  background-image: url("parallax02.34e9fde7.jpg");
}

.parallax03 {
  background-image: url("parallax03.46ed3c32.jpg");
}

.parallax04 {
  background-image: url("parallax04.7eb37e44.jpg");
}

.parallax05 {
  background-image: url("parallax05.c3899a12.jpg");
}

.page-content.dark h1, .page-content.dark h2, .page-content.dark h3, .page-content.dark h4, .page-content.dark h5, .page-content.dark h6 {
  color: #fff;
}

.page-content.dark p, .page-content.dark span, .page-content.dark li, .page-content.dark a {
  color: #eee;
}

[class^="col-"].custom-bkg {
  padding: 30px;
}

[class^="col-"].custom-bkg.dark h1, [class^="col-"].custom-bkg.dark h2, [class^="col-"].custom-bkg.dark h3, [class^="col-"].custom-bkg.dark h4, [class^="col-"].custom-bkg.dark h5, [class^="col-"].custom-bkg.dark h6 {
  color: #fff;
}

[class^="col-"].custom-bkg.dark p, [class^="col-"].custom-bkg.dark a, [class^="col-"].custom-bkg.dark span, [class^="col-"].custom-bkg.dark li, [class^="col-"].custom-bkg.dark label {
  color: #eee;
}

.accordion .title {
  width: 100%;
  float: left;
  background-color: #f6f6f6;
  margin-bottom: 10px;
  padding: 10px 15px;
  position: relative;
}

.accordion .title a {
  color: #333;
  width: 100%;
  float: left;
  padding-left: 20px;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  display: block;
  position: relative;
}

.accordion .title:before {
  content: "";
  font-family: FontAwesome;
  font-size: 15px;
  display: block;
  position: absolute;
}

.accordion .title.active:before {
  content: "";
  font-family: FontAwesome;
  font-size: 15px;
  display: block;
  position: absolute;
}

.accordion .title a:after {
  display: none;
}

.accordion.careers .title {
  padding: 15px 62px 15px 20px;
}

.accordion.careers .title a {
  padding-left: 0;
}

.accordion.careers .title:before {
  display: none;
}

.accordion.careers .title a span {
  color: #333;
  width: 30%;
  float: left;
  font-size: 13px;
  font-weight: 600;
  display: block;
}

.accordion .job-position, .accordion .job-end-date {
  text-transform: uppercase;
}

.accordion.careers .title:after {
  content: "";
  width: 52px;
  height: 100%;
  -o-transition: all .2s ease 0s;
  background-color: #e6e6e6;
  background-image: url("accordion-closed.f6354750.png");
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.accordion.careers .title.active:after, .accordion.careers .title:hover:after {
  background-image: url("accordion-opened.17aa9a59.png");
  background-position: center;
  background-repeat: no-repeat;
}

.accordion .content {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
}

blockquote {
  color: #333;
  border-left: 3px solid;
  margin-left: 30px;
  padding: 15px 20px;
  font-size: 18px;
  font-style: italic;
  line-height: 25px;
}

.btn {
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  float: right;
  border-radius: 25px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn span, .dark .btn span {
  color: #fff;
}

.btn-big {
  padding: 12px 60px;
  font-size: 12px;
}

.btn-medium {
  padding: 10px 40px;
  font-size: 12px;
}

.btn-small {
  padding: 4px 20px;
  font-size: 11px;
}

.btn-yellow {
  background-color: #fac312 !important;
}

.btn-centered {
  float: none;
  margin: 0 auto;
  display: table;
}

.btn-yellow:hover {
  background-color: #fcc820 !important;
}

.call-to-action .text {
  width: 70%;
  float: left;
}

.call-to-action .btn {
  float: right;
  position: relative;
  top: 30px;
}

#client-carousel .owl-item img {
  opacity: .7;
  -o-transition: all .2s ease-in-out 0s;
  transition: all .2s ease-in-out;
}

#client-carousel .owl-item:hover img {
  opacity: 1;
}

.clients-li {
  width: 100%;
}

.clients-li li {
  float: left;
  width: 33.3333%;
  border: 1px solid #eee;
  padding: 20px 15px;
  list-style: none;
}

.clients-li li:first-child {
  padding-left: 0;
}

.clients-li:nth-child(3n) {
  padding-right: 0;
}

.clients-li li img {
  opacity: .7;
  -o-transition: all .2s ease-in-out 0s;
  vertical-align: middle;
  margin: 0 auto;
  transition: all .2s ease-in-out;
  display: block;
}

.clients-li li:hover img {
  opacity: 1;
}

.col-md-6 .clients-li li {
  max-height: 92px;
}

.company-timeline {
  width: 100%;
}

.company-timeline li {
  float: left;
  width: 100%;
  list-style: none;
  position: relative;
}

.company-timeline li .timeline-item-details:before {
  content: "";
  width: 1px;
  height: 100%;
  z-index: 1;
  background-color: #ddd;
  display: block;
  position: absolute;
  top: 0;
  left: 35px;
}

.company-timeline .icon-date-container {
  width: 70px;
  height: 70px;
  float: left;
  z-index: 2;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  position: relative;
}

.company-timeline .icon-date-container i {
  width: 70px;
  height: 70px;
  vertical-align: middle;
  text-align: center;
  font-size: 24px;
  display: table-cell;
}

.company-timeline .timeline-item-details {
  padding-bottom: 30px;
  padding-left: 100px;
  position: relative;
}

.company-timeline li:last-child .timeline-item-details {
  padding-bottom: 0;
}

.row .custom-heading:only-child {
  margin-bottom: -70px;
}

.custom-heading {
  width: 100%;
  text-transform: uppercase;
  padding-top: 12px;
  display: block;
  position: relative;
}

.custom-heading:after {
  content: "";
  width: 40px;
  height: 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-heading.centered {
  text-align: center;
}

.custom-heading.centered:after {
  content: "";
  width: 40px;
  height: 3px;
  margin-left: -20px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.col-md-12 .custom-heading02:only-child {
  margin-bottom: 0;
}

.row .custom-heading02:only-child {
  margin-bottom: -30px;
}

.custom-heading02 {
  margin-bottom: 40px;
  position: relative;
}

.custom-heading02 h1, .custom-heading02 h2, .custom-heading02 h3, .custom-heading02 h4 {
  text-transform: none;
  text-align: center;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 30px;
}

.custom-heading02 p {
  text-transform: uppercase;
  text-align: center;
}

.custom-heading02:after {
  content: "";
  width: 40px;
  height: 3px;
  margin-left: -20px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.custom-heading02.simple h1, .custom-heading02.simple h2, .custom-heading02.simple h3, .custom-heading02.simple h4 {
  padding-bottom: 15px;
}

.driver-app-form fieldset {
  width: 33.3333%;
  float: left;
  padding-right: 15px;
}

.driver-app-form fieldset:nth-child(3n) {
  padding-right: 0;
}

.driver-app-form .wpcf7-select {
  max-height: 34px;
}

.table-responsive {
  overflow-y: hidden;
}

.events-table thead {
  border-bottom: 1px solid #e6e6e6;
}

.events-table thead th {
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 10px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 800;
}

.events-table thead th:first-child {
  padding-left: 0;
}

.events-table tbody td {
  vertical-align: middle;
  padding: 0 15px;
}

.events-table .event-date {
  padding: 20px 0;
}

.events-table .event-date .day {
  color: #333;
  text-align: center;
  background-color: #fcfcfc;
  padding: 20px 30px;
  font-size: 36px;
  font-weight: 800;
  line-height: 36px;
}

.events-table .event-date .month {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-weight: 700;
}

.intro-title {
  width: 100%;
}

.intro-title p {
  color: #333;
  text-align: center;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 24px;
  line-height: 30px;
}

.pi-latest-posts li {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  list-style: none;
}

.pi-latest-posts li:last-child {
  margin-bottom: 0;
}

.pi-latest-posts li .post-media {
  width: 100px;
  height: 100px;
  float: left;
  margin-bottom: 0;
}

.pi-latest-posts li .post-details {
  padding-left: 120px;
}

.pi-latest-posts li .post-details h4 {
  -o-transition: all .2s ease-in-out 0s;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
}

.pi-latest-posts .post-date p {
  color: #565f66;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.pi-latest-posts .post-date i {
  padding-right: 3px;
}

.pi-latest-posts02 li {
  width: 30%;
  float: left;
  margin-right: 30px;
  list-style: none;
}

.col-md-8 .pi-latest-posts02 li, .col-md-9 .pi-latest-posts02 li {
  width: 46%;
}

.col-md-6 .pi-latest-posts02 li, .col-md-4 .pi-latest-posts02 li, .col-md-3 .pi-latest-posts02 li {
  width: 100%;
  margin-bottom: 20px;
}

.pi-latest-posts02 li:nth-child(3n) {
  padding-right: 0;
}

.pi-latest-posts02 .post-date, .pi-latest-posts02 .post-date {
  width: 100px;
  float: left;
}

.pi-latest-posts02 .post-date .day {
  color: #333;
  text-align: center;
  background-color: #fcfcfc;
  padding: 20px 30px;
  font-size: 36px;
  font-weight: 800;
  line-height: 36px;
}

.pi-latest-posts02 .post-date .month {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-weight: 700;
}

.pi-latest-posts02 .post-details {
  padding-left: 120px;
}

.pi-latest-posts02 li .post-details h4 {
  -o-transition: all .2s ease-in-out 0s;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
}

.pi-latest-posts02 .post-category p {
  color: #565f66;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.pi-latest-posts02 .post-category i {
  padding-right: 3px;
}

.pi-latest-posts03 li {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  list-style: none;
}

.pi-latest-posts03 li:last-child {
  margin-bottom: 0;
}

.pi-latest-posts03 li .post-media {
  width: 60px;
  height: 60px;
  float: left;
  margin-bottom: 0;
}

.pi-latest-posts03 li .post-media i {
  font-size: 36px;
}

.pi-latest-posts03 li .post-details {
  padding-left: 70px;
}

.pi-latest-posts03 li .post-details h4 {
  -o-transition: all .2s ease-in-out 0s;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
}

.pi-latest-posts03 .post-date p {
  color: #565f66;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.col-md-8 .pi-latest-posts03 li, .col-md-9 .pi-latest-posts03 li {
  width: 48%;
  margin-right: 30px;
}

.col-md-8 .pi-latest-posts03 li:nth-child(2n), .col-md-9 .pi-latest-posts03 li:nth-child(2n) {
  margin-right: 0;
}

.col-md-6 .pi-latest-posts03 li, .col-md-4 .pi-latest-posts03 li, .col-md-3 .pi-latest-posts03 li {
  width: 100%;
  margin-bottom: 20px;
}

.fa-ul li i {
  line-height: 20px;
}

.fa-ul.large-icons {
  margin-left: 0;
}

.fa-ul.large-icons li {
  margin-bottom: 20px;
}

.fa-ul.large-icons li:last-child {
  margin-bottom: 0;
}

.fa-ul.large-icons li i {
  width: 30px;
  height: 30px;
  font-size: 24px;
}

.fa-ul.large-icons .icon-container {
  float: left;
  width: 30px;
  height: 30px;
}

.fa-ul.large-icons .li-content {
  padding-left: 40px;
}

.fa-ul.large-icons .li-content h4 {
  padding-top: 3px;
}

.numbers-counter {
  background-color: #fcfcfc;
  padding: 20px;
}

.numbers-counter .counter-container {
  width: 100%;
  position: relative;
}

.numbers-counter .counter-container:after {
  content: "";
  width: 30px;
  height: 3px;
  margin-left: -15px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.numbers-counter .number {
  text-align: center;
  color: #333;
  width: 100%;
  margin-bottom: 5px;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  display: block;
}

.numbers-counter p {
  text-align: center;
}

.promo-box {
  padding: 90px 20px;
}

.promo-box02 {
  padding: 30px 30px 90px;
}

.promo-box02 p, .promo-box h4, .promo-box p {
  text-align: center;
}

.promo-box .btn {
  float: none;
  margin: 0 auto;
  display: table;
}

.promo-bkg01 {
  background-image: url("promo01.0e0cb891.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.promo-bkg02 {
  background-image: url("promo02.4fe24b78.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.services-negative-top {
  margin-top: -316px;
}

.custom-bkg .service-feature-box, .parallax .service-feature-box {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: none;
}

.service-feature-box {
  border: 1px solid #eee;
  box-shadow: 1px 0 3px #ddd;
}

.service-feature-box .service-media {
  position: relative;
  overflow: hidden;
}

.service-feature-box .service-media img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  -o-transition: all 2s ease-in-out 0s;
  opacity: 1;
  transition: all 2s ease-in-out;
}

.service-feature-box .service-media:hover img {
  opacity: .7;
  transform: scale(1.3);
}

.service-feature-box .service-media a {
  text-transform: uppercase;
  background-color: #1b2936;
  position: absolute;
  bottom: 0;
  right: 0;
}

.service-feature-box .service-media span {
  color: #fff;
  padding-left: 20px;
  font-weight: 600;
}

.service-feature-box .service-media i {
  -o-transition: all .2s ease 0s;
  background-color: #2f3840;
  margin-left: 20px;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 22px;
  transition: all .2s;
}

.service-feature-box .service-body {
  background-color: #fff;
  padding: 20px;
}

.services-gallery .col-md-3 {
  padding: 0;
  list-style: none;
}

.service-item-container {
  position: relative;
  overflow: hidden;
}

.service-item-container .service-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
}

.service-item-container .service-item img {
  width: 100%;
  -o-transition: all 2s ease-in-out 0s;
  transition: all 2s ease-in-out;
}

.service-item-container .hover-mask-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  -o-transition: all .2s ease 0s;
  background-color: #31393f80;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.service-item-container .service-item:hover .hover-mask-container {
  opacity: 1;
}

.service-item-container figcaption {
  width: 100%;
  position: absolute;
  top: 40px;
}

.service-item-container figcaption h1, .service-item-container figcaption h2, .service-item-container figcaption h3, .service-item-container figcaption h4, .service-item-container figcaption h5 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: relative;
}

.service-item-container figcaption h1:after, .service-item-container figcaption h2:after, .service-item-container figcaption h3:after, .service-item-container figcaption h4:after, .service-item-container figcaption h5:after {
  content: "";
  width: 40px;
  height: 3px;
  margin-left: -20px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.hover-mask-container .hover-details {
  -o-transition: all .2s ease 0s;
  transition: all .2s;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(0, 100px);
}

.hover-mask-container .hover-details span {
  text-transform: uppercase;
  color: #fff;
  -o-transition: all .2s ease 0s;
  border: 3px solid;
  border-radius: 25px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all .2s;
}

.service-item-container .service-item:hover .hover-details {
  transform: translate(0);
}

.service-item-container .service-item:hover img {
  transform: scale(1.3);
}

.service-icon-center .icon-container {
  width: 100px;
  height: 100px;
  background-color: #fcfcfc;
  border: 2px solid #ddd;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: table;
}

.service-icon-center .icon-container i {
  color: #333;
  vertical-align: middle;
  text-align: center;
  width: 100px;
  height: 100px;
  font-size: 36px;
  display: table-cell;
}

.service-icon-center .icon-container img {
  width: 60px;
  height: 60px;
  margin-top: 15px;
  margin-left: -30px;
  position: relative;
  left: 50%;
}

.service-icon-center h1, .service-icon-center h2, .service-icon-center h3, .service-icon-center h4, .service-icon-center h5 {
  text-align: center;
  margin-bottom: 15px;
}

.service-icon-center p {
  text-align: center;
}

.service-icon-center-boxed {
  width: 100%;
  background-color: #fcfcfc;
  padding: 30px 20px;
}

.service-icon-center-boxed .service-title {
  margin: 0 auto 10px;
  display: table;
}

.service-icon-center-boxed .service-title .icon-container {
  width: 60px;
  height: 60px;
  float: left;
}

.service-icon-center-boxed .service-title h4 {
  padding-top: 20px;
  padding-left: 70px;
}

.service-icon-center-boxed p {
  text-align: center;
  padding-bottom: 0;
}

.service-icon-center-boxed .icon-container i {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  font-size: 46px;
  display: table-cell;
}

.service-icon-left {
  width: 100%;
}

.service-icon-left .icon-container {
  float: left;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.service-icon-left .icon-container img, .service-icon-left .icon-container svg {
  width: 60px;
  vertical-align: middle;
  height: 60px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 20px;
}

.service-icon-left .service-details {
  padding-left: 120px;
}

.service-icon-left .service-details h1, .service-icon-left .service-details h2, .service-icon-left .service-details h3, .service-icon-left .service-details h4 {
  margin-bottom: 15px;
}

.service-icon-left .icon-container i {
  text-align: center;
  vertical-align: middle;
  height: 100px;
  width: 100px;
  font-size: 60px;
  display: table-cell;
}

.service-icon-left-boxed {
  background-color: #fcfcfc;
  padding: 30px 40px;
}

.service-icon-left-boxed .icon-container {
  float: left;
  width: 100px;
}

.service-icon-left-boxed .service-details {
  padding-left: 120px;
}

.service-icon-left-boxed .service-details h1, .service-icon-left-boxed .service-details h2, .service-icon-left-boxed .service-details h3, .service-icon-left-boxed .service-details h4 {
  margin-bottom: 15px;
}

.service-icon-left-boxed .service-details p {
  padding-bottom: 0;
}

.col-md-3 .service-icon-left-boxed .icon-container {
  float: none;
  margin: 0 auto 20px;
}

.col-md-3 .service-icon-left-boxed .service-details {
  padding-left: 0;
}

.col-md-3 .service-icon-left-boxed .service-details h1, .col-md-3 .service-icon-left-boxed .service-details h2, .col-md-3 .service-icon-left-boxed .service-details h3, .col-md-3 .service-icon-left-boxed .service-details h4, .col-md-3 .service-icon-left-boxed .service-details p {
  text-align: center;
}

.service-icon-left-boxed i {
  vertical-align: middle;
  text-align: center;
  width: 100px;
  font-size: 70px;
  display: table-cell;
}

.service-list li {
  width: 100%;
  float: left;
  padding-bottom: 10px;
  list-style: none;
}

.service-list li:last-child {
  padding-bottom: 0;
}

.service-list li .icon-container {
  width: 70px;
  height: 70px;
  float: left;
}

.service-list li p {
  padding-top: 20px;
  padding-left: 0;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 18px;
}

.service-list li i {
  vertical-align: middle;
  text-align: center;
  width: 70px;
  font-size: 46px;
  display: table-cell;
}

.col-md-9 .service-list-big-icons li {
  width: 33.3%;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-9 .service-list-big-icons li:nth-child(3n) {
  padding-right: 0;
}

.col-md-9 .service-list-big-icons li:first-child {
  padding-left: 0;
}

.service-list-big-icons {
  width: 100%;
}

.service-list-big-icons li {
  float: left;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;
}

.service-list-big-icons li .icon-container {
  width: 80px;
  height: 80px;
  float: left;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.service-list-big-icons .icon-container svg, .service-list-big-icons .icon-container img {
  width: 60px;
  height: 100%;
  margin: 0 auto;
  display: block;
}

.service-list-big-icons li h4 {
  padding-top: 30px;
  padding-left: 100px;
  font-weight: normal;
}

.service-list-big-icons .icon-container i {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  text-align: center;
  font-size: 36px;
  display: table-cell;
}

.service-list-big-icons-details li {
  margin-bottom: 20px;
}

.col-md-9 .service-list-big-icons-details li {
  width: 33.3%;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-9 .service-list-big-icons-details li:nth-child(3n) {
  padding-right: 0;
}

.col-md-9 .service-list-big-icons-details li:first-child {
  padding-left: 0;
}

.service-list-big-icons-details {
  width: 100%;
}

.service-list-big-icons-details li {
  float: left;
  list-style: none;
}

.service-list-big-icons-details li .icon-container {
  width: 100px;
  height: 100px;
  float: left;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.service-list-big-icons-details li .icon-container svg, .service-list-big-icons-details li .icon-container img {
  width: 60px;
  height: 100%;
  vertical-align: middle;
  margin: 0 auto;
  display: table-cell;
}

.service-list-big-icons-details li .service-details {
  padding-top: 10px;
  padding-left: 125px;
}

.service-list-big-icons-details li .service-details h4 {
  margin-bottom: 15px;
}

.service-list-big-icons-details .icon-container i {
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
  font-size: 50px;
  display: table-cell;
}

.wpcf7.shipping-quote {
  background-color: #fcfcfc;
  padding: 20px;
}

.wpcf7.shipping-quote label {
  width: 50%;
  float: left;
  padding-top: 8px;
}

.wpcf7.shipping-quote fieldset {
  width: 100%;
  margin-bottom: 5px;
}

.wpcf7.shipping-quote input.wpcf7-text {
  width: 50%;
  float: left;
  padding: 5px 15px;
}

.wpcf7.shipping-quote .submit {
  text-transform: uppercase;
  float: right;
  color: #fff;
  -o-transition: all .2s ease 0s;
  border: none;
  border-radius: 25px;
  margin-top: 10px;
  padding: 8px 30px;
  font-size: 13px;
  font-weight: 700;
  transition: all .2s;
}

.header-style01 + .master-slider {
  margin-top: -185px !important;
}

.master-slider .pi-caption01 {
  color: #fff;
  text-transform: uppercase;
  font-size: 72px;
  font-weight: 800;
}

.master-slider .pi-text {
  color: #fff;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 30px;
}

.master-slider {
  margin-bottom: 70px !important;
}

.master-slider.mb-0 {
  margin-bottom: 0 !important;
}

.master-slider.mb-30 {
  margin-bottom: 30px !important;
}

.master-slider .pi-caption02 {
  text-transform: uppercase;
  color: #fff;
  font-family: Raleway, Open Sans, Arial, sans-serif;
  font-size: 21px;
  font-weight: 800;
}

.master-slider .pi-button {
  color: #fff;
  text-transform: uppercase;
  -o-transition: all .2s ease 0s;
  border: 2px solid;
  border-radius: 25px;
  padding: 12px 50px;
  font-weight: 700;
  transition: all .2s;
}

.master-slider .pi-caption-dark {
  color: #03253c;
}

.master-slider .ms-view {
  background: none;
}

.master-slider .tooltip h1, .master-slider .tooltip h2, .master-slider .tooltip h3, .master-slider .tooltip h4, .master-slider .tooltip h5, .master-slider .tooltip h6 {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-bottom: 10px;
  position: relative;
}

.master-slider .tooltip h6:after {
  content: "";
  width: 15px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.master-slider .tooltip p, .master-slider .tooltip span {
  color: #333;
}

.master-slider .tooltip img {
  float: left;
}

.master-slider .tooltip-text {
  padding-left: 85px;
}

.ms-skin-default .ms-tooltip {
  max-width: 300px;
}

.statement p {
  text-align: center;
  padding: 0 50px;
  font-size: 24px;
  line-height: 30px;
}

.table {
  width: 100%;
}

.table caption {
  text-align: left;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
}

.table thead {
  background-color: #f6f6f6;
}

.table thead tr th {
  color: #333;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 700;
}

.table thead tr th:first-child {
  text-align: left;
}

.table tbody {
  background-color: #fcfcfc;
}

.table tbody tr td {
  text-align: center;
  padding: 10px;
}

.table tbody tr td:first-child {
  text-align: left;
  color: #333;
  font-weight: 700;
}

.tabs {
  overflow: hidden;
}

.tabs li {
  float: left;
  cursor: pointer;
  background-color: #fcfcfc;
  margin-right: 2px;
  padding: 0;
  line-height: 55px;
  list-style: none;
  position: relative;
  top: 3px;
  overflow: hidden;
}

.tabs li a {
  color: #333;
  text-transform: uppercase;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 800;
  line-height: 55px;
  display: block;
}

.tab-content-wrap {
  width: 100%;
  float: left;
  padding-top: 30px;
  overflow: hidden;
}

.tabs li.active a {
  color: #fff;
}

.team-member img {
  margin-bottom: 20px;
}

.team-details {
  position: relative;
}

.team-details:after {
  content: "";
  width: 40px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.team-details h1, .team-details h2, .team-details h3, .team-details h4, .team-details h5 {
  text-transform: uppercase;
  margin-bottom: 0;
}

.team-details .position {
  font-style: italic;
}

.team-list li {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  list-style: none;
}

.team-list li:last-child {
  margin-bottom: 0;
}

.team-list li img {
  float: left;
}

.team-list li .team-details-container {
  padding-left: 293px;
}

.team-list li .team-details {
  margin-bottom: 20px;
}

.col-md-3 .team-list li img, .col-md-4 .team-list li img {
  float: none;
  width: 100%;
  margin: 0 auto 20px;
  display: table;
}

.col-md-3 .team-list li .team-details-container, .col-md-4 .team-list li .team-details-container {
  padding-left: 0;
}

.testimonial {
  background-color: #fcfcfc;
  padding: 30px;
}

.testimonial p {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  line-height: 22px;
}

.testimonial-author p {
  text-transform: uppercase;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.tracking {
  width: 100%;
  position: relative;
}

.tracking .package-id {
  height: 40px;
  min-width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 60px 5px 15px;
}

.tracking .submit {
  width: 40px;
  height: 40px;
  -o-transition: all .2s ease 0s;
  background-image: url("tracking-arrow.4d0b80d1.png");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 3px;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.vehicle-gallery .col-md-3 {
  padding: 0;
  list-style: none;
}

.gallery-item-container {
  position: relative;
  overflow: hidden;
}

.gallery-item-container .gallery-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
}

.gallery-item-container .gallery-item img {
  width: 100%;
  height: auto;
  -o-transition: all 2s ease-in-out 0s;
  transition: all 2s ease-in-out;
  transform: scale(1);
}

.gallery-item-container .hover-mask-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  -o-transition: all .2s ease 0s;
  background-color: #31393f80;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-item-container .gallery-item:hover .hover-mask-container {
  opacity: 1;
}

.gallery-item-container figcaption {
  width: 100%;
  position: absolute;
  top: 40px;
}

.gallery-item-container figcaption h1, .gallery-item-container figcaption h2, .gallery-item-container figcaption h3, .gallery-item-container figcaption h4, .gallery-item-container figcaption h5 {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: relative;
}

.gallery-item-container figcaption h1:after, .gallery-item-container figcaption h2:after, .gallery-item-container figcaption h3:after, .gallery-item-container figcaption h4:after, .gallery-item-container figcaption h5:after {
  content: "";
  width: 40px;
  height: 3px;
  margin-left: -20px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.hover-mask-container .hover-zoom {
  width: 50px;
  height: 50px;
  -o-transition: all .2s ease 0s;
  border: 2px solid;
  border-radius: 50%;
  margin-bottom: -25px;
  margin-left: -25px;
  transition: all .2s;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(0, 50%);
}

.hover-mask-container .hover-zoom a {
  vertical-align: middle;
  text-align: center;
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: table-cell;
}

.gallery-item-container .gallery-item:hover .hover-zoom {
  transform: translate(0);
}

.gallery-item-container .gallery-item:hover img {
  transform: scale(1.3);
}

.vehicle-gallery .col-md-4 {
  padding: 0;
  list-style: none;
}

.col-md-4 .gallery-item-container figcaption {
  top: 20px;
}

.page-content.fixed.centered .container {
  position: absolute;
  top: 50%;
  left: 50%;
}

.page-content.fixed.centered .container .row {
  max-width: 100%;
}

.page-content.fixed.bottom .row {
  max-width: 100%;
  margin-bottom: 0;
}

.page-content.fixed.bottom .container {
  position: absolute;
  bottom: 0;
  left: 50%;
}

.blog-posts li {
  list-style: none;
}

.blog-posts .pagination {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.blog-posts .pagination ul {
  float: right;
}

.blog-posts .pagination li {
  float: left;
  width: 30px;
  height: 30px;
  -o-transition: all .2s ease 0s;
  background-color: #f5f9fc;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 5px;
  list-style: none;
  transition: all .2s;
}

.blog-posts .pagination li a {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.blog-posts .pagination li.active a, .blog-posts .pagination li:hover a {
  color: #fff;
}

.blog-post .post-body h3 {
  -o-transition: all .3s ease 0s;
  transition: all .3s;
}

.blog-posts.post-list .blog-post {
  border-bottom: 1px dotted #ddd;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.post-list .blog-post .post-date, .post-single .blog-post .post-date {
  width: 100px;
  float: left;
}

.post-list .blog-post .post-date .day, .post-single .blog-post .post-date .day {
  color: #333;
  text-align: center;
  background-color: #fcfcfc;
  padding: 20px 30px;
  font-size: 36px;
  font-weight: 800;
  line-height: 36px;
}

.post-list .blog-post .post-date .month, .post-single .blog-post .post-date .month {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-weight: 700;
}

.post-list .blog-post .post-body, .post-single .blog-post .post-body {
  padding-left: 130px;
}

.post-list .blog-post .post-body h3 {
  -o-transition: all .2s ease 0s;
  font-size: 18px;
  transition: all .2s;
}

.post-media {
  width: 100%;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 25px;
  overflow: hidden;
}

.post-media .post-img img {
  -o-transition: all 2s ease 0s;
  opacity: 1;
  transition: all 2s;
}

.post-media .post-img:hover img {
  opacity: .7;
  transform: scale(1.15);
}

.blog-posts.isotope.masonry {
  padding: 0;
}

.blog-posts.isotope.masonry li.blog-post.isotope-item {
  width: 30%;
  float: left;
  background-color: #fff;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.blog-post.isotope-item .post-info {
  margin-bottom: 30px;
}

.blog-post.isotope-item .post-date {
  float: left;
  width: 50%;
}

.blog-post.isotope-item .post-date p {
  color: #565f66;
  text-transform: uppercase;
  padding: 10px 0 0 20px;
  font-size: 12px;
  font-weight: 600;
}

.blog-post.isotope-item .post-date i {
  padding-right: 3px;
}

.blog-post.isotope-item .post-info .post-category {
  width: 50%;
  float: right;
  position: relative;
}

.blog-post.isotope-item .post-info .post-category a {
  text-transform: uppercase;
  color: #eee;
  background-color: #565f66;
  padding: 7px 15px;
  position: absolute;
  right: 0;
}

.blog-post.isotope-item .post-body {
  padding: 0 20px;
}

.blog-post.isotope-item .post-media {
  margin-bottom: 15px;
}

.blog-post.isotope-item .post-footer {
  padding: 0 15px 15px 20px;
}

.post-footer .post-meta {
  width: 50%;
  float: left;
  padding-top: 3px;
}

.post-footer .post-meta li {
  float: left;
  border-right: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
}

.post-footer .post-meta li:first-child {
  padding-left: 0;
}

.post-footer .post-meta li:last-child {
  border-right: none;
}

.post-footer .post-meta li.comments a {
  padding-left: 3px;
}

.post-footer .post-meta li.post-like:before {
  float: right;
  position: relative;
  top: 6px;
}

.post-footer .post-meta li.post-like .box {
  padding-top: 12px;
  position: relative;
  top: -12px;
}

.post-footer .post-meta li.post-like .box a {
  padding-right: 15px;
}

.post-footer a.read-more span {
  text-transform: none;
  font-weight: normal;
}

.post-footer .post-meta li.post-like:hover:before {
  color: #ff0101;
}

.post-comments {
  width: 100%;
  float: left;
  margin-top: 30px;
}

.comments-li {
  float: left;
  background: none;
  margin-bottom: 30px;
  padding-left: 0;
  list-style: none;
}

.comments-li > li {
  float: left;
  width: 100%;
  min-height: 75px;
  margin-bottom: 30px;
  list-style: none;
}

.comments-li > li:last-child .comment {
  border: none;
  margin-bottom: 0;
}

.comments-li .comment {
  float: left;
  min-height: 60px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.post-comments .comment + .children, .post-comments .children + .children {
  margin-top: 30px;
}

.comment .avatar {
  width: 70px;
  height: 70px;
  float: left;
  border: 3px solid #eee;
  border-radius: 50%;
  margin-right: 30px;
  overflow: hidden;
}

.comment-meta li {
  font-style: italic;
  list-style: none;
}

.comment-meta .author {
  color: #252525;
  font-family: Raleway, Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.comment .comment-body {
  margin-top: 15px;
  margin-left: 100px;
}

.comment .comment-reply-link {
  cursor: pointer;
  text-transform: uppercase;
  float: right;
  background: url("reply.0fa6e470.png") 0 no-repeat;
  margin-top: 15px;
  padding-left: 22px;
  font-weight: 600;
  display: block;
}

.post-comments .children {
  float: left;
  width: 90%;
  margin-left: 10%;
  padding: 0 0 0 20px;
}

.children li {
  list-style: none;
}

.comment-form {
  width: 100%;
  float: left;
}

.comment-form fieldset {
  width: 50%;
  float: right;
  margin-bottom: 20px;
  padding-right: 30px;
}

.comment-form fieldset:nth-child(2n+1) {
  padding-right: 0;
}

.comment-form .wpcf7-message {
  width: 100%;
}

.comment-form label {
  width: 100%;
  margin-bottom: 5px;
  display: block;
}

.comment-reply {
  text-transform: uppercase;
  float: right;
  color: #fff;
  -o-transition: all .2s ease 0s;
  border: none;
  border-radius: 25px;
  padding: 13px 40px;
  font-size: 13px;
  font-weight: 700;
  transition: all .2s;
}

.page-title-map.page-title-negative-top #map {
  height: 800px;
  margin-top: -186px;
}

.locations-li > li {
  margin-bottom: 30px;
  list-style: none;
}

.locations-li > li h3 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.locations-li > li .fa-ul {
  margin-left: 20px;
}

.wpcf7 fieldset {
  margin-bottom: 15px;
}

.wpcf7 label {
  width: 100%;
  padding-bottom: 10px;
}

.wpcf7-text, .wpcf7-select, .wpcf7-textarea {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 8px 15px;
}

.wpcf7 .wpcf7-submit {
  text-transform: uppercase;
  float: right;
  color: #fff;
  -o-transition: all .2s ease 0s;
  border: none;
  border-radius: 25px;
  padding: 13px 40px;
  font-size: 13px;
  font-weight: 700;
  transition: all .2s;
}

#map {
  width: 100%;
  height: 450px;
  margin-bottom: 20px;
}

#map img {
  max-width: none;
}

.aside-widgets > li {
  list-style: none;
}

.widget {
  width: 100%;
  margin-bottom: 45px;
  display: block;
}

.widget .title h3 {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
}

.widget ul > li {
  background-image: url("arrow.52de2e3f.png");
  background-position: 0;
  background-repeat: no-repeat;
  padding-left: 15px;
  list-style: none;
}

.widget li a {
  padding-top: 5px;
  display: block;
}

.widget_search form {
  position: relative;
}

.widget_search .a_search {
  width: 100%;
  color: #777;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 60px 10px 10px;
  font-style: italic;
  display: block;
  top: 0;
  left: 0;
}

.widget_search .search-submit {
  width: 40px;
  height: 100%;
  text-indent: -9999px;
  cursor: pointer;
  min-height: 40px;
  background-image: url("search.ca34069e.png");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0 3px 3px 0;
  position: absolute;
  top: 0;
  right: 0;
}

.rpw_posts_widget ul > li {
  background: none;
  border-bottom: 1px dotted #ddd;
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding-left: 18px;
  position: relative;
  background-image: none !important;
}

.rpw_posts_widget ul > li:before {
  content: "";
  font-family: FontAwesome;
  font-size: 11px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.rpw_posts_widget ul > li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.rpw_posts_widget li h4 {
  -o-transition: all .2s ease 0s;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  transition: all .2s;
}

.widget_nav_menu ul li {
  background-position: 100%;
  border-bottom: 1px dotted #ddd;
  padding-left: 0;
}

.widget_nav_menu ul li:last-child {
  border-bottom: none;
}

.widget_newsletterwidget .newsletter {
  width: 100%;
  float: left;
  position: relative;
}

.widget_newsletterwidget .newsletter .email {
  height: 40px;
  min-width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 60px 5px 15px;
  font-style: italic;
}

.footer-dark .footer-widget-container .newsletter .email {
  color: #bcc0c4;
  background-color: #565f66;
  border: none;
}

.newsletter .submit {
  width: 40px;
  height: 40px;
  -o-transition: all .2s ease 0s;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 3px;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.submit.visible {
  background-image: url("subscribe.1da6a74f.png");
}

.news-carousel-container .icon-title {
  float: left;
  min-height: 80px;
  border-right: 3px solid #1f2830;
  margin-right: 20px;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 20px;
}

.news-carousel-container .icon-title i, .news-carousel-container .icon-title h3 {
  float: left;
  font-size: 24px;
}

.news-carousel-container .icon-title i {
  padding-right: 10px;
}

.news-carousel-container .owl-item {
  padding-top: 5px;
}

.news-carousel-container .owl-item h4 {
  margin-bottom: 5px;
}

.news-carousel-container .owl-item span {
  text-transform: uppercase;
}

[class^="col-"].custom-bkg .news-carousel-container {
  margin: -15px;
}

#footer .row {
  margin-bottom: 25px;
}

#footer-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
  position: relative;
}

#footer-wrapper.visible {
  background-image: url("footer-bkg.4a207373.png");
}

#footer-wrapper.footer-dark {
  background-color: #25272e;
}

#footer-wrapper.footer-light {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.footer-dark .footer-widget-container .text-big, .footer-dark .footer-widget-container .widget .title h3 {
  color: #fff;
}

.footer-widget-container > li {
  list-style: none;
}

.footer-dark .footer-widget-container p, .footer-dark .footer-widget-container a, .footer-dark .footer-widget-container address, .footer-dark .footer-widget-container span, .footer-dark .copyright-container p, .footer-dark .copyright-container a, .footer-dark .copyright-container span {
  color: #bcc0c4;
}

.footer-social-icons {
  margin-top: 15px;
}

.footer-social-icons > li {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  background-image: none !important;
  padding: 0 !important;
  list-style: none !important;
}

.footer-social-icons li a {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
  border-radius: 3px;
  padding: 0;
  display: table-cell;
}

.footer-social-icons li a:hover {
  color: #fff;
}

.footer-dark .footer-social-icons li a {
  color: #fff;
  background-color: #565f66;
}

.footer-light .footer-social-icons li a {
  background-color: #f6f6f6;
}

.copyright-container .row {
  margin-bottom: 0;
}

.footer-dark .copyright-container .row {
  border-top: 1px solid #384653;
  padding: 15px 0 10px;
}

.footer-light .copyright-container .row {
  border-top: 1px solid #ddd;
  padding: 15px 0 10px;
}

.copyright-container a, .copyright-container p, .copyright-container span {
  font-size: 11px;
}

.scroll-up {
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  z-index: 100;
  background-color: #252525;
  background-image: url("to-top.5541a4f3.png");
  background-repeat: no-repeat;
  border-radius: 4px;
  display: none;
  position: fixed;
  bottom: 15px;
  right: 100px;
}

.logo {
  height: 100px;
}

.logo img {
  height: 100px;
  width: 322px;
}

@media (max-width: 998px) {
  .logo {
    height: 75px;
  }

  .logo img {
    height: 75px;
    width: 242px;
  }
}

/*# sourceMappingURL=index.afd75aa8.css.map */
